<template>
  <div class="">
    <MainTitle>
      <template #before>
        {{ $t('shopFrom') }}
      </template>
      {{ $t('title') }}
    </MainTitle>
    <ul class="[ grid grid-cols-2 gap-x-12 md:gap-x-22 gap-y-12 ] mt-12 lg:flex items-center justify-between">
      <li class="flex flex-col text-center items-center">
        <svg-icon name="graph/deals" width="40" height="40" class="mb-2" />
        <h3 class="text-primary-700 text-lg-1 font-bold">{{ $t('info1') }}</h3>
        <p class="text-secondary-700 text-base text-center">{{ $t('desc1') }}</p>
      </li>
      <li class="flex flex-col text-center items-center">
        <svg-icon name="graph/wallet" width="40" height="40" class="mb-2" />
        <h3 class="text-primary-700 text-lg-1 font-bold">{{ $t('info2') }}</h3>
        <p class="text-secondary-700 text-base text-center">{{ $t('desc2') }}</p>
      </li>
      <li class="flex flex-col text-center items-center">
        <svg-icon name="graph/wrench" width="40" height="40" class="mb-2" />
        <h3 class="text-primary-700 text-lg-1 font-bold">{{ $t('info3') }}</h3>
        <p class="text-secondary-700 text-base text-center">{{ $t('desc3') }}</p>
      </li>
      <li class="flex flex-col text-center items-center">
        <svg-icon name="graph/measurements" width="40" height="40" class="mb-2" />
        <h3 class="text-primary-700 text-lg-1 font-bold">{{ $t('info4') }}</h3>
        <p class="text-secondary-700 text-base text-center">{{ $t('desc4') }}</p>
      </li>
      <li class="flex flex-col text-center items-center">
        <svg-icon name="graph/payments" width="40" height="40" class="mb-2" />
        <h3 class="text-primary-700 text-lg-1 font-bold">{{ $t('info5') }}</h3>
        <p class="text-secondary-700 text-base text-center">{{ $t('desc5') }}</p>
      </li>
    </ul>
  </div>
</template>
<script setup lang="ts">
const { t: $t } = useI18n({
  useScope: 'local',
});
</script>
<i18n>
{
    "en": {
        "title": "Miraco",
        "info1": "Exclusive Offers",
        "desc1": "Best Deals for Air Conditioners",
        "info2": "Free Shipping",
        "desc2": "Free delivery at your doorstep",
        "info3": "Free Installation",
        "desc3": "Free Installation when you shop online",
        "info4": "Find the Right Fit",
        "desc4": "Get the most compatible product for your room",
        "info5": "Payment Facilities",
        "desc5": "Pay in Cash, Credit or Debit Cards",
        "shopFrom": "Why Shop From"
    },
    "ar": {
        "title": "ميراكو",
        "info1": "عروض حصرية",
        "desc1": "أفضل عروض التكييفات",
        "info2": "شحن مجاني",
        "desc2": "توصيل مجاني حتى الباب",
        "info3": "تركيب مجاني",
        "desc3": "التركيب مجاني عندما تتسوق عبر الإنترنت",
        "info4": "اعثر على المنتج المناسب",
        "desc4": "احصل على أكثر المنتجات مناسبة لغرفتك",
        "info5": "تسهيلات الدفع",
        "desc5": "نقدًا عند الاستلام أو ببطاقة الائتمان أو الخصم",
        "shopFrom": "لماذا عليك الشراء من "
    }
}
</i18n>
